import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";

// Routes
import { canNavigate } from "@/libs/acl/routeProtection";
import { isUserLoggedIn } from "@admin/../auth/utils.js";

import admin from "@/app/admin/routes";
import { getHomeRouteForLoggedInUser, RoleClime  } from '@/app/admin/auth/utils'
 import jwtDecode from "jwt-decode";

Vue.use(VueRouter);

export const All = "all";
export const Admin = "Admin";
export const Guide = "Guide";
export const Editor = "Editor";
export const SuperAdmin = "SuperAdmin";
export const Teacher = 'Teacher';
export const DataEntry = 'DataEntry'
const router = new VueRouter({
    mode: "history",
    base: process.env.BASE_URL,
    scrollBehavior() {
        store.dispatch("app/setLoading", false);
        return { x: 0, y: 0 };
    },
    routes: [
        {
            path: "/",
            redirect: "/admin"
        },
        {
            path: "/admin",
            components: {
                default: () => import("@/app/admin/Admin.vue"),
                "bread-actions": () =>
                    import(
                        "@/app/admin/layouts/components/AdminBreadActions.vue"
                    )
            },
            children: admin
        },
        {
            path: "/login",
            name: "login",
            component: () => import("@/app/admin/auth/Login.vue"),
            meta: () => ({
                pageTitle: "login",
                roles: [All],
                redirectIfLoggedIn: true
            })
        },
        {
            path: "/access-denied",
            name: "access-denied",
            component: () => import("@global/pages/NotAuthorized.vue"),
            meta: () => ({
                pageTitle: "access-denied",
                roles: [All]
            })
        },
        {
            path: "/server-error",
            name: "server error",
            component: () => import("@global/pages/server-error.vue"),
            meta: () => ({
                pageTitle: "conection-error",
                roles: [All]
            })
        },
        {
            path: "/error-404",
            name: "error 404",
            meta: () => ({
                pageTitle: "not-found",
                roles: [All]
            }),
            component: () => import("@global/pages/error-404.vue")
        },

        {
            path: "*",
            redirect: "/error-404"
        }
    ]
});

router.beforeEach((to, _, next) => {
    const isLoggedIn = isUserLoggedIn();
    document.title = !to.meta(to).breadcrumb
        ? !to.meta(to).pageTitle
            ? to.meta(to).title
            : to.meta(to).pageTitle
        : to.meta(to).breadcrumb[0].text;
    if (!canNavigate(to)) {
        // Redirect to login if not logged in
        if (!isLoggedIn) return next("/login");
        const user = JSON.parse(localStorage.getItem('userData'))
  
         // If logged in => not authorized
         return next(getHomeRouteForLoggedInUser(user ? jwtDecode(user.accessToken)[RoleClime] : null)); 
     
    }
    return next();
});

export default router;
